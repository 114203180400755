export const jobTitle = [
    {
        id: 0,
        active: true,
        title: 'ENTERPRISE SYSTEMS ANALYST'
    },{
        id: 1,
        active: true,
        title: 'TECHNICAL PROJECT MANAGER'
    },{
        id: 2,
        active: true,
        title: 'SALESFORCE DEVELOPER / ADMIN'
    },{
        id: 4,
        active: true,
        title: 'FRONT-END DEVELOPER'
    }, {
        id: 3,
        active: true,
        title: 'FULL-STACK DEVELOPER'
    }
];

export const aboutMe = [
    {
        id: 0,
        active: false,
        content: 'Hi there! My name is Keny Lin.'
    },{
        id: 1,
        active: true,
        content: 'I am an experienced Technical Program Manager with over 15 years of expertise in leading complex, cross-functional initiatives across IT and operations. I specialize in managing large-scale technical programs, driving product roadmaps, and collaborating closely with engineering and product teams to deliver impactful, results-driven solutions. With a proven ability to optimize processes, enhance operational efficiency, and align projects with strategic business goals, I consistently deliver results on time and within budget.'
    },{
        id: 2,
        active: true,
        content: 'My background spans software development, system-level technical design, and the implementation of CRM systems like Salesforce and Oracle EBS. I have extensive experience in communicating with executive leadership, defining KPIs, and managing high-performing teams in dynamic environments. Certified as a Salesforce Administrator, I am passionate about leveraging technology to drive business growth and innovation.'
    },{
        id: 3,
        active: true,
        content: 'I am also a Systems Analyst and Developer, proficient in full-stack development, front-end technologies, and AI/ML. Skilled in Python, C++, JavaScript, TypeScript, and familiar with modern frameworks, libraries and UI/UX principles, I thrive in fast-paced environments, solving complex challenges through a blend of technical expertise and strategic insight. Bilingual in English and Mandarin Chinese, I bring a global perspective to every project I undertake.'
    }
];

export const aboutMeSolution = [
    {
        id: 0,
        active: true,
        title: 'Enterprise IT & Systems Solutions',
        content: 'Enterprise Systems & Applications expert with 13 years of experience designing and developing enterprise solutions. Skilled in BPR, ERP, CRM, BI, and data integration. 11 years of IT leadership in manufacturing, focusing on strategy, roadmaps, compliance, and operational excellence.'
    },{
        id: 1,
        active: true,
        title: 'Program & Project Management',
        content: 'IT Program/Project Manager with 15 years of experience leading technical projects and business initiatives. Strong in communication, Agile methodologies, cross-team collaboration, and delivering projects on time and within scope.'
    },{
        id: 2,
        active: true,
        title: 'Salesforce Solutions',
        content: 'Salesforce Administrator, Developer, and Consultant with 12 years of experience in Sales Cloud, CPQ, Service Cloud, Marketing Cloud, and Tableau CRM (Einstein Analytics). Proficient in Salesforce configuration, development (Apex, Visualforce, Lightning), and API integrations like MuleSoft.'
    }
    ,{
        id: 3,
        active: true,
        title: 'Full-Stack Developer',
        content: 'A seasoned web developer with 15 years of experience specializing in both front-end and back-end development. Proficient in front-end UI/UX design with expertise in HTML, CSS, JavaScript and TypeScript, coupled with a strong understanding of DOM manipulation. Skilled in server-side API integration using REST, SOAP, and GraphQL, and well-versed in Python, C++, JavaScript for building robust back-end solutions with frameworks like Flask, Django and Node.js. Continuously evolving by mastering modern web frameworks such as React and Vue.js to stay aligned with industry trends and best practices.'
    }
];

export const leftSkills =[
    {
        id: 0,
        active: true,
        title: 'Program and Project Management',
        content: 'SDLC, Waterfall, Agile, Scrum'
    },{
        id: 1,
        active: true,
        title: 'Technical Leadership & Cross-Functional Supervision',
        content: 'Empathetic Leadership with Strong Communication Skills'
    },{
        id: 2,
        active: true,
        title: '',
        content: 'IT Operations, Strategy, Roadmap Creation, Budget Control, Vendor Management, ITSM, ITIL'
    },{
        id: 3,
        active: true,
        title: 'Process & Performance Optimization',
        content: 'Expertise in Process, Performance, and Quality Improvement Initiatives'
    },{
        id: 4,
        active: true,
        title: 'Enterprise Systems',
        content: 'Oracle EBS, Salesforce, Jira, G Suite, Microsoft 365, ADP'
    }
    ,{
        id: 5,
        active: true,
        title: 'Business Intelligence & Reporting',
        content: 'ETL, MuleSoft, Data Analysis, Tableau, Salesforce Analytics, Power BI, Analyzer'
    }
];

export const rightSkills =[
    {
        id: 0,
        active: true,
        title: 'Programming Languages',
        content: 'Python, C++, JavaScript, TypeScript, SQL, HTML5, CSS3, JSON, Tailwind, Salesforce Apex'
    },{
        id: 1,
        active: true,
        title: 'Frameworks & Technologies',
        content: 'PyTorch, TensorFlow, Flask, REST/SOAP, GraphQL, React, Vue.js, Node.js, Express, Mocha, Chai, Three.js, Flutter, WordPress, UI/UX Principles'
    },{
        id: 2,
        active: true,
        title: 'Development & Collaboration Tools (CI/CD, Version Control)',
        content: 'Git, Docker, Jenkins, PyCharm, Photoshop, Figma'
    },{
        id: 3,
        active: true,
        title: 'Database Management',
        content: 'MS SQL, Oracle, MySQL, MongoDB, Salesforce SAQL, SOQL, SOSL'
    },{
        id: 4,
        active: true,
        title: 'Cloud Platforms',
        content: 'Google Cloud Platform (GCP), Microsoft Azure, Alibaba Cloud (AliCloud)'
    }
    ,{
        id: 5,
        active: true,
        title: 'AI/ML Expertise',
        content: 'Computer Vision, OpenCV, Matplotlib, YOLO, MediaPipe'
    }
];