import { useQuery } from "react-query";
import { request, gql } from "graphql-request";

const endpoint = "https://countries.trevorblades.com/";
const query_countries_all = gql`
  {
    countries {
      name,
      capital,
      emoji,
      phone,
      currency,
      code,
      languages {
        name,
        code
      },
      continent {
        name
      }
    }
  }`;
const query_countries_currency = gql`
  query QueryCountry($currency: String! = "USD") {
    countries(filter: { currency: { eq: $currency }}) {
      name,
      capital,
      emoji,
      phone,
      currency,
      code,
      languages {
        name,
        code
      },
      continent {
        name
      }
    }
  }`;

export default function CountryQuery() {
    function showCAll() {
        document.getElementById('CAll').className = 'CQ-table';
        document.getElementById('CUSD').className = 'CQ-table1';
        document.getElementById('CEUR').className = 'CQ-table1';
    }
    function showCUSD() {
        document.getElementById('CAll').className = 'CQ-table1';
        document.getElementById('CUSD').className = 'CQ-table';
        document.getElementById('CEUR').className = 'CQ-table1';
    }
    function showCEUR() {
        document.getElementById('CAll').className = 'CQ-table1';
        document.getElementById('CUSD').className = 'CQ-table1';
        document.getElementById('CEUR').className = 'CQ-table';
    }
    function hideAll() {
        document.getElementById('CAll').className = 'CQ-table1';
        document.getElementById('CUSD').className = 'CQ-table1';
        document.getElementById('CEUR').className = 'CQ-table1';
    }

    const { data: data1, isLoading: isLoading1, isError: isError1 } = useQuery("countries", () => {
        return request(endpoint, query_countries_all);
    });
    const { data: data2, isLoading: isLoading2, isError: isError2 } = useQuery("countries2", () => {
        return request(endpoint, query_countries_currency, {currency: "USD"});
    });
    const { data: data3, isLoading: isLoading3, isError: isError3 } = useQuery("countries3", () => {
        return request(endpoint, query_countries_currency, {currency: "EUR"});
    });

    if (isLoading1 || isLoading2 || isLoading3) return "Loading...";
    if (isError1 || isError2 || isError3) return <pre>{isError1}</pre>;

    return (
        <>
            Click button to show result --> <button onClick={showCAll}>All Countries</button>&nbsp;
            <button onClick={showCUSD}>Currency USD Countries</button>&nbsp;
            <button onClick={showCEUR}>Currency EUR Countries</button>&nbsp;
            <button onClick={hideAll}>Hide All</button>&nbsp;
            <br />&nbsp;
            <table className="CQ-table1" id="CAll">
                <tbody>
                    <tr>
                        <td className="CQ-title-td" align="center">Name</td>
                        <td className="CQ-title-td" align="center">Code</td>
                        <td className="CQ-title-td" align="center">Emoji</td>
                        <td className="CQ-title-td" align="center">Capital</td>
                        <td className="CQ-title-td" align="center">Region</td>
                        <td className="CQ-title-td" align="center">Currency</td>
                        <td className="CQ-title-td" align="center">Phone</td>
                        <td className="CQ-title-td" align="center">Language</td>
                    </tr>
                    {
                        data1.countries.map((countries) =>
                            <tr key={countries.code}>
                                <td key={`1_${countries.code}`} className="CQ-td">{countries.name}</td>
                                <td key={`2_${countries.code}`} className="CQ-td" align="center">{countries.code}</td>
                                <td key={`3_${countries.code}`} className="CQ-td" align="center">{countries.emoji}</td>
                                <td key={`4_${countries.code}`} className="CQ-td">{countries.capital}</td>
                                <td key={`5_${countries.code}`} className="CQ-td">{countries.continent.name}</td>
                                <td key={`6_${countries.code}`} className="CQ-td" align="center">{countries.currency}</td>
                                <td key={`7_${countries.code}`} className="CQ-td">+{countries.phone}</td>
                                <td key={`8_${countries.code}`} className="CQ-td">{countries.languages.map(language => <div key={`9_${countries.code}_${language.name}`}>{language.name}</div>)}</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
            <table className="CQ-table1" id="CUSD">
                <tbody>
                    <tr>
                        <td className="CQ-title-td" align="center">Name</td>
                        <td className="CQ-title-td" align="center">Code</td>
                        <td className="CQ-title-td" align="center">Emoji</td>
                        <td className="CQ-title-td" align="center">Capital</td>
                        <td className="CQ-title-td" align="center">Region</td>
                        <td className="CQ-title-td" align="center">Currency</td>
                        <td className="CQ-title-td" align="center">Phone</td>
                        <td className="CQ-title-td" align="center">Language</td>
                    </tr>
                    {
                        data2.countries.map((countries) =>
                            <tr key={countries.code}>
                                <td key={`1_${countries.code}`} className="CQ-td">{countries.name}</td>
                                <td key={`2_${countries.code}`} className="CQ-td" align="center">{countries.code}</td>
                                <td key={`3_${countries.code}`} className="CQ-td" align="center">{countries.emoji}</td>
                                <td key={`4_${countries.code}`} className="CQ-td">{countries.capital}</td>
                                <td key={`5_${countries.code}`} className="CQ-td">{countries.continent.name}</td>
                                <td key={`6_${countries.code}`} className="CQ-td" align="center">{countries.currency}</td>
                                <td key={`7_${countries.code}`} className="CQ-td">+{countries.phone}</td>
                                <td key={`8_${countries.code}`} className="CQ-td">{countries.languages.map(language => <div key={`9_${countries.code}_${language.name}`}>{language.name}</div>)}</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
            <table className="CQ-table1" id="CEUR">
                <tbody>
                    <tr>
                        <td className="CQ-title-td" align="center">Name</td>
                        <td className="CQ-title-td" align="center">Code</td>
                        <td className="CQ-title-td" align="center">Emoji</td>
                        <td className="CQ-title-td" align="center">Capital</td>
                        <td className="CQ-title-td" align="center">Region</td>
                        <td className="CQ-title-td" align="center">Currency</td>
                        <td className="CQ-title-td" align="center">Phone</td>
                        <td className="CQ-title-td" align="center">Language</td>
                    </tr>
                    {
                        data3.countries.map((countries) =>
                            <tr key={countries.code}>
                                <td key={`1_${countries.code}`} className="CQ-td">{countries.name}</td>
                                <td key={`2_${countries.code}`} className="CQ-td" align="center">{countries.code}</td>
                                <td key={`3_${countries.code}`} className="CQ-td" align="center">{countries.emoji}</td>
                                <td key={`4_${countries.code}`} className="CQ-td">{countries.capital}</td>
                                <td key={`5_${countries.code}`} className="CQ-td">{countries.continent.name}</td>
                                <td key={`6_${countries.code}`} className="CQ-td" align="center">{countries.currency}</td>
                                <td key={`7_${countries.code}`} className="CQ-td">+{countries.phone}</td>
                                <td key={`8_${countries.code}`} className="CQ-td">{countries.languages.map(language => <div key={`9_${countries.code}_${language.name}`}>{language.name}</div>)}</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </>
    );
}